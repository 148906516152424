/* eslint-disable import/no-anonymous-default-export */
export default {

    dedPorDependente: 2275.08,
    dedPoreducacao: 3561.50,
    inss_faxa1: 1320.00,
    inss_faxa2: 2571.29,
    inss_faxa3: 3856.94,
    inss_teto: 7507.49,
    irpf_faxa1: 24511.92,
    irpf_faxa2: 33919.81,
    irpf_faxa3: 45012.61,
    irpf_faxa4: 55976.16,
    aliq_max_pensao: 1,

}